.Card {
  width: 20vw;
  .Img {
    height: 10vw;
    border-radius: 1vw;
  }
  .title {
    font-size: 1.2vw;
    font-family: sans-serif;
    color: #363636;
  }
  .link {
    color: #004e8c;
    font-size: 1.1vw;
  }
  @media only screen and (max-width: 600px) {
    width: 50vw;

    .Img {
      height: 36vw;
    }

    .title {
      font-size: 4.2vw;
    }

    .link {
      font-size: 3.1vw;
    }
  }
}
