.title {
  font-family: inherit;
  font-size: 3vw;
  margin-top: 2vw;
  font-weight: 300;
  color: #363636;
}

.subTitle {
  font-family: inherit;
  font-size: 1vw;
  margin-top: 0.5vw;
  font-weight: 200;
  color: #363636;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 8vw;
    margin-top: 4vw;
  }

  .subTitle {
    font-size: 4vw;
    margin-top: 2.5vw;
  }
}
