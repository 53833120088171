.InputContainer {
  input {
    margin-top: 1vw;
    font-size: 0.95vw;
    width: 32.34vw;
    height: 2.8vw;
    outline: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 6px 6px 20px #0000000f;
    border: 1px solid #eeeeee;
    border-radius: 0.5vw;
    padding-left: 3.3vw;
  }
  .Label {
    font-size: 1.02vw;
    font-weight: 500;
    text-align: left;
  }
}

.PasswordContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.PasswordContainer i {
  font-size: 1vw;
  position: absolute;
  right: 0.5vw;
  margin-top: 0.8vw;
  cursor: pointer;
}
