.carousel-root {
  width: 74% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 12vw !important;
  height: 12vw !important;
  border-radius: 50%;
  object-fit: contain;
}

.carouselDesc {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 30vh;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.carouselDesc h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.carouselDesc h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.carouselDesc p {
  font-weight: 100 !important;
  line-height: 1.3vw !important;
  color: #222;
  font-size: 1.1vw;
  font-family: sans-serif;
  max-height: 67px;
}

.carouselDesc p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.carouselDesc p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .carousel .slide {
    .carouselCard {
      img {
        width: 32vw !important;
        height: 32vw !important;
      }
      .carouselDesc {
        background: #fafafa;
        margin-top: -15vw;
        width: 88%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 18vw;
        padding-bottom: 12.5%;
        padding-left: 5%;
        padding-right: 5%;
        border: 1px solid #ddd;
        height: 100vw;
        p {
          font-weight: 100 !important;
          line-height: 5vw !important;
          color: #222;
          font-size: 2.8vw;
          font-family: sans-serif;
        }
      }
    }
  }
}
