.Register {
  height: 92vh;
  background-size: cover;
  display: flex;
  color: white;
}

.RegisterOverlay::after {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  top: 8vh;
  width: 100%;
  height: 92vh;
  z-index: 1;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.3;
}

.RegisterContainer {
  border-radius: 0.7vw;
  width: 37.1vw;
  padding-bottom: 1vw;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.Title {
  margin-top: 2.2vw;
}

.Title p {
  font-size: 4.1vw;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: bold;
  line-height: 1;
  color: white;
}

.Register input {
  width: 26.34vw;
}

.Fields {
  width: fit-content;
  margin: auto;
  margin-top: 1.7vw;
}

.Footer {
  font-size: 1vw;
  display: flex;
  justify-content: space-between;
  margin-top: 2.3vw;
  margin: auto;
}

.FormContainer {
  margin: auto;
  width: 29.9vw;
}

.FormContainer form {
  grid-row-gap: 0.6vw;
  display: grid;
}

.FormContainer > div {
  margin-top: 1vw;
}

.Register input[type="checkbox"] {
  width: unset;
  outline: none;
  border: none;
}

.Register input[type="checkbox"]:hover {
  width: unset;
  outline: none;
  border: none;
}

.CheckboxContainer {
  display: flex;
  align-items: center;
}

.FooterButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 0.5vw;
}

.Submit {
  background: white;
  color: #800e12;
}

.Submit:hover {
  background: white radial-gradient(circle, transparent 1%, white 1%)
    center/15000%;
  color: #800e12;
}

.Submit:active {
  background-color: #ebe4e4;
  background-size: 100%;
  transition: background 0s;
}

.ForgetPassword button {
  width: 100%;
}
