.mainTitle {
  font-family: inherit;
  font-size: 4vw;
  margin-top: 2vw;
  letter-spacing: 1vw;
  font-weight: 300;
  color: #363636;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}
