.BgWithText {
  height: 34vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.999;
  position: relative;

  .overlayImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    aspect-ratio: 9/16;
    object-fit: cover;
  }
}

.BgWithText:after {
  position: absolute;
  content: " ";
  top: 8vh;
  left: 0;
  height: 27;
  width: 100%;
  z-index: -1;
  background: black 0% 0% no-repeat padding-box;
  opacity: 0.4;
}

.TextContainer {
  h1 {
    font-size: 9vmin;
    color: #8bf;
    text-align: left;
    font-family: Lato, sans-serif;
    font-weight: 700;
    margin: 1rem 0 1rem 2rem;
  }
  @keyframes typing {
    0.0000%,
    26.5116% {
      content: "";
    }
    1.1163%,
    25.3721% {
      content: "L";
    }
    2.2326%,
    24.2326% {
      content: "La";
    }
    3.3488%,
    23.0930% {
      content: "Lan";
    }
    4.4651%,
    21.9535% {
      content: "Lang";
    }
    5.5814%,
    20.8140% {
      content: "Langu";
    }
    6.6977%,
    19.6744% {
      content: "Langua";
    }
    7.8140%,
    18.5349% {
      content: "Languag";
    }
    8.9302%,
    17.3953% {
      content: "Language";
    }

    28.1395%,
    49.9069% {
      content: "";
    }
    29.2558%,
    48.7674% {
      content: "O";
    }
    30.3721%,
    47.6279% {
      content: "Op";
    }
    31.4884%,
    46.4884% {
      content: "Opp";
    }
    32.6047%,
    45.3488% {
      content: "Oppor";
    }
    33.7209%,
    44.2093% {
      content: "Opport";
    }
    34.8372%,
    43.0698% {
      content: "Opportun";
    }
    35.9535%,
    41.9302% {
      content: "Opportuni";
    }
    37.0698%,
    40.7907% {
      content: "Opportunitie";
    }
    38.1860%,
    39.6512% {
      content: "Opportunities";
    }

    56.3953%,
    77.1628% {
      content: "";
    }
    57.5116%,
    76.0233% {
      content: "Y";
    }
    58.6279%,
    74.8837% {
      content: "Yo";
    }
    59.7442%,
    73.7442% {
      content: "You";
    }
  }

  @keyframes blink {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

  .typewriter {
    --caret: currentcolor;
  }

  .typewriter::before {
    content: "";
    animation: typing 13.5s infinite;
  }

  .typewriter::after {
    content: "";
    border-right: 1ch solid var(--caret);
    animation: blink 0.5s linear infinite;
  }

  @media (prefers-reduced-motion) {
    .typewriter::after {
      animation: none;
    }

    @keyframes sequencePopup {
      0%,
      100% {
        content: "Language";
      }
      33% {
        content: "Opportunities";
      }
      66% {
        content: "You";
      }
    }

    .typewriter::before {
      content: "Language";
      animation: sequencePopup 12s linear infinite;
    }
  }
}

@media only screen and (max-width: 600px) {
  .BgWithText {
    height: 90vh;
  }
  .Title {
    text-align: center;
    font-size: 6.5vw;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 1;
    font-weight: bold;
    z-index: 1;
    margin-bottom: 3vw;
    text-align: center;
  }
  .OverlayText {
    font-size: 8.5vw;
    text-align: center;
  }
}
