.Header {
  width: 100%;
  height: 8vh;
  background: #e5e3e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 9999;
  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .Link {
      font-size: 1.1vw;
    }
  }
}

.Logo {
  border-radius: 10vw;
  display: flex;
  width: 5.6vw;
  height: 5.6vw;
  z-index: 2;
  position: relative;
  align-items: center;
  margin-left: 4vw;
}

.Logo > img:last-child {
  position: absolute;
}

.Logo > img {
  width: 100%;
}

.LinksContainer {
  height: 100%;
  width: 58%;
  align-items: center;
  display: flex;
  margin-right: 1%;
  justify-content: space-between;
  color: #363636;
  font-size: 0.95vw;
}

.LinksContainer a,
.ProfileLink {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0.05% 1.5%;
  cursor: pointer;
  color: #363636;
  text-decoration: none;
}

.LinksContainer .ActiveLink {
  // border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 0.2vw 0.78vw;
}

.LinksContainer a:last-child {
  padding: 0 0;
  text-align: justify;
}

.LinksContainer .Link:last-child img {
  cursor: pointer;
  width: 1.875vw;
  height: 1.875vw;
  vertical-align: text-bottom;
  font-weight: 600;
  color: #131212;
}

.HamMenu {
  display: none;
}

.Profile {
  visibility: hidden;
  /* width: 9.3vw; */
  background: #ffffff;
  font-size: 1.04vw;
  font-weight: 500;
  padding: 0.94vw 1.25vw;
  border-radius: 0.2vw;
  display: flex;
  position: absolute;
  z-index: 1;
  right: 2vw;
  flex-flow: column;
  grid-row-gap: 0.7vw;
}

.ProfileContainer:hover .Profile {
  visibility: visible;
  z-index: 3;
}

.Profile div,
.Profile a {
  color: #000000;
}

@media only screen and (max-width: 600px) {
  .Header {
    height: unset;
    padding: 1vw 4vw;

    .right {
      display: flex;
      height: 100%;

      .HamMenu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        nav {
          display: flex;
          flex-direction: column;
          gap: 2vw;
          justify-content: flex-start;
        }

        .ActiveLink {
          padding: 1vw 0vw;
          outline-offset: 0px;
          outline: none;

          .Link {
            text-decoration: none;
            color: white;
            font-size: 4vw;
            padding-inline: 0;
          }
        }
      }
    }
  }
  .Logo {
    width: 18.6vw;
    height: 18.6vw;
    z-index: 2;
    position: relative;
    margin-left: 0vw;
  }
  .LinksContainer {
    display: none;
    width: 0;
    height: 0;
  }

  .Logo > img {
    height: 19.6vw;
  }
  .Logo > img:last-child {
    position: absolute;
    height: 20vw;
  }
}
