.AccordionContainer {
  display: flex;
  flex-direction: column;
  gap: 2vw;
  padding: 2vw 4vw;
  .Accordion {
    .title {
      font-size: 3vw;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.5vw;
      border-radius: 2vw;
      border: 0.2vw solid gray;
      cursor: pointer;
    }
    .left {
      display: flex;
      align-items: center;
      img {
        width: 8vw;
        height: 8vw;
        border-radius: 1vw;
        margin-right: 2vw;
      }
    }
    .body {
      padding: 1vw;
    }
  }
}
