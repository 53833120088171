* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  position: relative;
}

.LargeText {
  font-size: 2.7vw;
  font-weight: bold;
}

.brown {
  color: #004e8c;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "poppins";
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "poppins";
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "poppins";
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: "poppins";
}

.PrimaryTextBold {
  font-size: 2.7vw;
  color: #800e12;
  font-weight: bold;
}

.LargeText {
  font-size: 2.7vw;
  font-weight: bold;
}

.HeadingText {
  text-transform: capitalize;
  font-size: 2.7vw;
  font-weight: bold;
}

.btn-primary {
  outline: none;
  border: 1px solid #004e8c;
  cursor: pointer;
  margin-top: 1vw;
  color: #004e8c;
  font-size: 0.9vw;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #181d9233;
  border-radius: 0.54vw;
  opacity: 1;
  width: 9.5vw;
  height: 3.5vw;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  background-position: center;
  transition: background 0.8s;
}

.btn-primary:hover {
  background: #800e12 radial-gradient(circle, transparent 1%, #800e12 1%)
    center/15000%;
  color: white;
}
.btn-primary:active {
  background-color: #b5363b;
  background-size: 100%;
  transition: background 0s;
}
.btn-primary:disabled {
  opacity: 0.6;
}
.btn-brown {
  background: #800e12 radial-gradient(circle, transparent 1%, #800e12 1%)
    center/15000%;
  color: white;
}
.btn-secondary {
  cursor: pointer;
  margin-top: 1vw;
  color: #800e12;
  font-size: 0.9vw;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0 1px 1px rgba(24, 28, 146, 0.2);
  border: 1px solid #800e12;
  border-radius: 0.54vw;
  opacity: 1;
  width: 9.5vw;
  height: 3.5vw;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  background-position: center;
  transition: background 0.8s;
  outline: none;
}
.btn-secondary:hover {
  background: white radial-gradient(circle, transparent 1%, white 1%)
    center/15000%;
}
.btn-secondary:active {
  background-color: #ebe4e4;
  background-size: 100%;
  transition: background 0s;
}

.btn-black {
  outline: none;
  border: transparent;
  cursor: pointer;
  margin-top: 1vw;
  color: #ffffff;
  font-size: 0.9vw;
  background: #312e2e 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #181d9233;
  border-radius: 0.54vw;
  opacity: 1;
  width: 9.5vw;
  height: 3.5vw;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  background-position: center;
  transition: background 0.8s;
}

.yellow {
  color: #ffe826;
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.whiteButton {
  font-size: 1.4vw;
  box-shadow: 0px 10px 15px #181d9233;
  border: 2px solid #312e2e;
  border-radius: 0.7vw;
  opacity: 1;
  width: 14.3vw;
  height: 3.8vw;
  color: #312e2e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.3vw;
  cursor: pointer;
}

.black {
  color: black;
}

.brown {
  color: #004e8c;
}

.whiteCard {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.whiteCard:hover .brown {
  color: white;
}

.underline {
  text-decoration: underline;
}

.title-text-small {
  font-size: 1.27vw;
  font-weight: 500;
}

.border {
  margin: 2.5vw 0vw;
  background: #e2e2e2;
  height: 0.5px;
}

.flex {
  display: flex;
}

.text-center {
  text-align: center;
}

.semi-bold {
  font-weight: 600;
}

.small-font {
  font-size: 1.3vw;
}

.error-msg {
  font-size: 0.8vw;
  margin-top: 0.4vw;
  color: red;
  margin-left: 0.4vw;
}

.error {
  font-size: 1vw;
  color: red;
}

.display-none {
  display: none;
}

.membershipStatus {
  position: absolute;
  top: 6.5vw;
  z-index: 1;
  right: 0px;
}

.filter-button {
  width: 5.5vw;
  height: 2vw;
  border-radius: 0.324vw;
}

@media only screen and (max-width: 600px) {
  .filter-button {
    width: 23vw !important;
    height: 8vw !important;
    margin-top: 1vw !important;
  }
  .donation-button {
    margin-top: 1vw !important;
  }
  .DonationModal .label {
    font-size: 3.2vw !important;
  }
}

/*--------------PAGINATION--------------------*/
.pagination-container {
  display: flex;
  grid-column-gap: 1vw;
  list-style-type: none;
  padding: 0;
  margin-left: auto;
  justify-content: flex-end;
}

.pagination-container li {
  cursor: pointer;
  padding: 1px 6px;
}

.pagination-container a {
  outline: none;
}

.pagination-container .selected {
  background: #a52038;
  border-radius: 3px;
}
/*--------------PAGINATION END--------------------*/

/*--------------TOAST--------------------*/

.Toastify__toast--success {
  background-color: #800e12 !important;
  color: white !important;
}
@media only screen and (max-width: 600px) {
  .Toastify__toast {
    margin-bottom: 3px !important;
  }
}
@media only screen and (max-width: 600px) {
  .Toastify__toast-container {
    width: 100vw !important;
    padding: 3px !important;
  }
}

/*-----------DROPDOWN-----------*/
.Dropdown-control {
  margin-top: 16px;
  align-items: center !important;
  display: flex !important;
  font-size: 0.95vw !important;
  width: 31.8vw !important;
  height: 3vw !important;
  outline: none !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 6px 6px 20px #0000000f !important;
  border: 1px solid #eeeeee !important;
  border-radius: 0.5vw !important;
  padding-left: 3.3vw !important;
}

.Dropdown-menu {
  font-size: 0.95vw !important;
  /* width: 35.64vw !important; */
}

.Dropdown-arrow {
  border-color: #800e12 transparent transparent !important;
  right: 1vw !important;
  top: 1.2vw !important;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #800e12 !important;
}

.DropdownContainer .Dropdown-control {
  margin-top: unset !important;
  width: 7vw !important;
  padding-left: 2vw !important;
}

.DropdownContainer .Dropdown-menu {
  width: 7.5vw !important;
}

/*ACCORDION*/

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  margin: 20px 0px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  padding: 3.02vw 1vw 3.02vw 3vw;
  display: flex;
  cursor: pointer;
  text-align: left;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  justify-content: space-between;
  font-size: 1.5vw;
  font-weight: 500;
}

.accordion__button:focus {
  outline: none;
}

.accordion__button img {
  width: 22px;
  vertical-align: middle;
  margin-top: -2px;
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 0 20px 20px 20px;
  animation: fadein 0.35s ease-in;
}

.accordion__panel > p {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  opacity: 1;
  padding: 15px 20px;
}
/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*--ACCORDION--*/

/*-----------CAROUSEL------------*/

.carousel {
  position: relative;
  height: 27vw !important;
  width: 100% !important;
}

.carousel__back-button {
  /* background-image: url("./assets/leftArr.png"); */
  background-repeat: no-repeat;
  left: -72px;
  position: absolute;
  top: 8vw;
  height: 50px !important;
  width: 50px !important;
  border: none !important;
  background-color: transparent !important;
  outline: none;
}

.carousel__back-button:disabled {
  transform: rotate(360deg) !important;
  height: 50px !important;
  width: 50px !important;
  /* background-image: url("./assets/disabledArr.png"); */
}

.carousel__next-button:disabled {
  /* background-image: url("./assets/disabledArr.png"); */
  transform: rotate(180deg);
  background-repeat: no-repeat;
  right: -50px;
  position: absolute;
  top: 8vw;
  height: 50px !important;
  width: 50px !important;
  border: none !important;
  background-color: transparent !important;
  outline: none;
}

.carousel__next-button {
  /* background-image: url("./assets/rightArr.png"); */
  background-repeat: no-repeat;
  right: -50px;
  position: absolute;
  top: 8vw;
  height: 50px !important;
  width: 50px !important;
  border: none !important;
  background-color: transparent !important;
  outline: none;
}

.c_dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.carousel__dot {
  outline: none;
  border-radius: 50%;
  height: 0.81vw;
  cursor: pointer;
  width: 0.81vw;
  border: none;
  margin: 0.41vw;
}

.carousel__dot--selected {
  height: 0.81vw;
  cursor: pointer;
  width: 0.81vw;
  background: #800e12;
}

.withoutButtons .carousel {
  height: 11vw !important;
}

/*-----------------MODAL----------------------------*/

.react-responsive-modal-modal {
  border-radius: 2vw;
  max-width: unset !important;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: unset !important;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 7vw !important;
  padding: 0 !important;
  position: relative;
  overflow-y: auto;
}

.react-responsive-modal-closeButton {
  top: 1vw !important;
  right: 2vw !important;
  outline: none;
}

.react-responsive-modal-closeButton svg {
  height: 20px !important;
  width: 20px !important;
}

.react-responsive-modal-root {
  overflow-y: scroll !important;
}
.react-responsive-modal-container {
  height: unset !important;
}

/*-----------_RADIO_----------*/

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  display: none;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 14px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 11px;
  height: 11px;
  border: 1px solid #800e12;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 7px;
  height: 7px;
  background: #800e12;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 50%;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*------------------RADIO--------------*/

/*----------------HAMBURGER ICON ----------------------_*/

/* Position and sizing of burger button */
.bm-burger-button {
  text-align-last: center;
  position: absolute;
  top: 7vw;
  right: 10vw;
}

.bm-burger-button img {
  width: 60% !important;
  height: 60% !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  top: 13.058vw;
  position: fixed;
  height: 100%;
  width: 60% !important;
  top: 20.5vw;
  height: calc(100% - 20.5vw) !important;
}

/* General sidebar styles */
.bm-menu {
  background: #312e2e;
  padding: 0 1.5em 0;
  font-size: 1.15em;
  text-align: center;
}

.bm-cross-button {
  display: none;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  background: #312e2e;
  padding: 0.8em;
  color: white !important;
  display: grid;
  justify-content: end;
  height: 80vh !important;
  text-align: left;
}

/* Individual item */
.bm-item {
  width: max-content;
  height: max-content;
  padding: 1vw 4vw;
  outline-offset: 0px;
  outline: none;
  display: block;
  border: 1px solid transparent;
  border-radius: 4vw;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  display: none;
}

.fa-bars {
  font-size: 5vw;
  width: 100%;
  height: 100%;
  font-weight: 100;
  color: white;
}

/*----------------------PAYMENT-----------------__*/

.MyCardElement iframe:first-child {
  height: 2.9vw !important;
}

.MyCardElement .ElementsApp {
  height: 7vw !important;
}

.MyCardElement .ElementsApp,
.MyCardElement .ElementsApp .InputElement {
  font-size: 15px !important;
  color: #424770 !important;
  letter-spacing: 0.025em !important;
  font-family: Source Code Pro, monospace !important;
  margin-top: 1vw !important;
}

.DonationModal .label {
  font-size: 1.2vw;
}

@media only screen and (max-width: 600px) {
  .HeadingText,
  .PrimaryTextBold {
    font-size: 6.65vw;
  }
  .btn-primary {
    font-size: 3.2vw;
    border-radius: 2vw;
    width: 39vw;
    height: 12vw;
    margin-top: 3vw;
  }
  .btn-secondary {
    font-size: 3.2vw;
    border-radius: 2vw;
    width: 39vw;
    height: 12vw;
  }
  .whiteButton {
    font-size: 4vw;
    height: 12vw;
    width: 38.9vw;
    border-radius: 1.2vw;
    margin: 0 auto;
    border: 2px solid #ffffff;
    color: white;
    margin-bottom: 6vw;
  }

  .LargeText {
    font-size: 6.6vw;
  }

  .title-text-small {
    font-size: 3.7vw;
    font-weight: 500;
  }

  .small-font {
    line-height: 2;
    font-size: 3.2vw;
  }

  .error-msg {
    font-size: 2.8vw;
    margin-top: 1.4vw;
    margin-bottom: 1vw;
  }

  .btn-black {
    width: -webkit-fill-available;
    height: 12vw;
    font-size: 3.2vw;
    border-radius: 2vw;
  }
  /*---------------DROPDOWN---------------*/

  .Dropdown-control {
    height: 11vw !important;
    width: 84vw !important;
    font-size: 3.2vw !important;
  }

  .DropdownContainer .Dropdown-menu {
    width: -webkit-fill-available !important;
  }

  .Dropdown-arrow {
    right: 3vw !important;
    top: 5.2vw !important;
  }

  .Dropdown-menu {
    font-size: 3.2vw !important;
    width: 100% !important;
  }
  /*----------CAROUSEL---------------*/

  .carousel {
    height: 96vw !important;
    padding: 1vw;
    margin-left: 4%;
    width: 102% !important;
  }

  .carousel__next-button,
  .carousel__back-button {
    display: none;
  }

  .carousel__dot {
    height: 3vw;
    width: 0.81vw;
  }
  .carousel__dot--selected {
    height: 3vw;
    width: 0.81vw;
  }
  .c_dots {
    right: 5vw;
  }

  .withoutButtons .carousel {
    height: 40vw !important;
  }

  /*--------------ACCORDION---------------*/

  .accordion__button {
    font-size: 3.3vw;
  }

  .accordion__panel p {
    font-size: 2.8vw;
  }

  /*------------MODAL-------------*/
  .react-responsive-modal-modal {
    border-radius: 2.5vw;
  }
}

.login-modal .react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.8) !important;
}

@media only screen and (max-width: 600px) {
  .login-modal {
    display: flex !important;
    align-items: center !important;
  }
  .profile .Dropdown-control {
    margin-top: 1vw;
  }

  .bm-menu-wrap{
    width: 45% !important;
  }

  .bm-item-list {
    padding: 0;
    padding-top: 2vw;
  }
}

#tsparticles {
  position: absolute;
  left: 0;
  height: 100%;
}

.events-modal-container .react-responsive-modal-modal {
  background: white !important;
}

.filter-dropdown {
  width: 17vw;
  margin-bottom: 1vw;
}

.filter-dropdown .Dropdown-control {
  width: 100% !important;
  padding-left: 1vw !important;
}

.volunteerForm .Dropdown-control {
  margin-top: 12px !important;
  width: 35.8vw !important;
}

@media only screen and (max-width: 600px) {
  .volunteerForm .Dropdown-control {
    margin-top: 0 !important;
    width: auto !important;
  }
}

.eventDesc {
  max-height: 10vw;
  max-width: 10vw;
}

.highlight {
  color: red;
}
