.Main {
  // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: sans-serif;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    font-size: 3vw;
    font-weight: 400;
    text-align: center;
    margin: 2vw 0 1vw 0;
    color: #2a354f;
  }

  input {
    display: inline-block;
    width: 30vw;
    height: 4vw;
    margin: 0.5vw 1vw 1.5vw 0;
    padding: 1vw 1.1vw;
    font-size: 1.5vw;
    font-weight: 300;
    line-height: 1.5;
    color: #869ab8;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.13vw solid #dde1ed;
    border-radius: 0.5vw;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    outline: none;
    box-sizing: border-box;

    &.error {
      border: 0.13vw solid #fa5252;
    }
  }

  button {
    position: relative;
    display: inline-block;
    height: 4vw;
    padding: 1vw 1.1vw;
    letter-spacing: 0.025em;
    font-size: 1.5vw;
    font-weight: 400;
    border-radius: 0.5vw;
    border: none;
    color: #fff;
    background-color: #274991;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .Main {
    h1 {
      font-size: 5vw;
      margin: 4vw 0 3vw 0;
    }

    input {
      width: 42vw;
      height: 8vw;
      margin: 1.5vw 1.5vw 2vw 0;
      padding: 1.8vw 2vw;
      font-size: 3vw;
    }

    button {
      height: 8vw;
      padding: 1.8vw 2vw;
      font-size: 3vw;
    }
  }
}
