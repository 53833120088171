.CoursePage {
  padding-top: 8vh;

  .Tabs {
    margin-top: 10vh;
    .tabsHead {
      display: flex;
      align-items: center;
      list-style: none;
      justify-content: center;
      .tab {
        margin: 0vw 4vw;
        cursor: pointer;
        outline: none;
        display: flex;
        flex-direction: column;
        .tabTitle {
          font-size: 1.2vw;
          font-family: sans-serif;
          color: #004e8b;
          margin-top: 1vw;
          font-weight: 500;
        }
        img {
          height: 8vw;
          width: 8vw;
        }
      }
    }
  }
  .tabPanel {
    .List {
      padding: 1vw 10vw;
      .currentTab {
        display: none;
      }
    }
  }
  .subCourses {
    .listItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5vw 1vw;
      border: 1px solid #e5e3e2;
      border-radius: 0.6vw;
      background: #fcfcfc;
      box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1215686275);

      .left {
        display: flex;
        align-items: center;
        .profile {
          margin-right: 2vw;
          img {
            border-radius: 50%;
            overflow: hidden;
            height: 3vw;
            width: 3vw;
          }
        }
        .content {
          text-align: left;
          .title {
            font-size: 1.2vw;
            font-weight: 600;
          }
          .description {
            font-size: 1.1vw;
            font-weight: 400;
            color: #363636;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding-top: 0;
    min-height: 82vh;
    .Tabs {
      margin-top: 4vh;
      display: flex;
      align-items: flex-start;
      .tabsHead {
        flex-direction: column;

        .tab {
          margin: 2vw 4vw;
          .tabTitle {
            display: none;
          }
          img {
            height: 15vw;
            width: 15vw;
          }
        }
      }
    }

    .List {
      padding: 1vw 0vw 1vw 2vw;
      min-width: 70vw;
      .subCourses {
        display: flex;
        flex-wrap: wrap;
        gap: 3vw 8vw;
        .listItem {
          padding: 0;
          border-radius: 2.6vw;
          width: 40vw;
          height: 40vw;
          flex-direction: column;
          overflow: hidden;

          .left {
            width: 100%;
            height: 100%;
            flex-direction: column;
            .profile {
              margin-right: 2vw;
              display: flex;
              align-items: center;
              height: 60%;
              width: 100%;
              margin: 0;

              img {
                width: 100%;
                height: 100%;
                border-radius: 0;
                object-fit: cover;
              }
            }
            .content {
              text-align: center;
              padding-inline: 1vw;
              margin-top: 2vw;
              .description {
                font-size: 2.5vw;
                font-weight: 600;
                color: #363636;
              }
            }
          }
        }
      }
    }
  }
}
