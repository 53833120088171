.CourseContent {
  .courseContainer {
    min-width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 3vw;
    margin-bottom: 7vw;

    .courseCard {
      width: 18rem;
      cursor: pointer;
      .img {
        height: 18vw;
        border-radius: 0.8vw;
      }
      .courseTitle {
        font-size: 1.5vw;
        font-family: sans-serif;
        color: #363636;
        margin: 1vw 0vw 0.5vw 0vw;
      }
      .courseLink {
        font-family: sans-serif;
        font-size: 1.2vw;
        color: #004e8c;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .courseContainer {
      flex-direction: column;
      margin-top: 5vw;

      .courseCard {
        .img {
          height: 48vw;
          border-radius: 0.8vw;
        }
        .courseTitle {
          font-size: 3.5vw;
          margin: 1vw 0vw 1vw 0vw;
        }
        .courseLink {
          font-size: 2.2vw;
          display: block;
          margin-bottom: 5vw;
        }
      }
    }
  }
}
